<div class="container-fluid">
  <div class="row top_search_block">
    <div class="col-md-4">
      <div class="top_header_search">
        <div class="input-group mb-3">
          <input
            type="text"
            class="form-control"
            placeholder="Search"
            [(ngModel)]="search"
          />
          <div class="input-group-addon">
            <span class="input-group-text" id="basic-addon1"
              ><i class="fa fa-search"></i
            ></span>
          </div>
          <button
            class="btn btn-outline-secondary"
            type="button"
            (click)="removeFilterCategories()"
          >
            <i class="fa fa-filter" aria-hidden="true"></i>
          </button>
        </div>
      </div>
      <div *ngIf="showCategoryFilter">
        <ps-ui-select-dropdown
          [dropdownItems]="categories"
          [field]="'label'"
          [enableSearch]="true"
          labelNone="Filter Categories"
          [multi]="true"
          (selectedItems)="filterCategories($event)"
        >
        </ps-ui-select-dropdown>
      </div>
    </div>
    <div class="col-md-8">
      <div class="row">
        <div class="offset-md-2 col-md-6">
          <h1 class="question_heading text-center">Manage Questions</h1>
        </div>
      </div>
      <div class="row">
        <div class="offset-md-6 col-md-6">
          <button
            type="button"
            class="btn btn-square pull-right"
            [routerLink]="['./import-questions']"
          >
            Import Questions
          </button>
          <button
            type="button"
            class="btn btn-square pull-right"
            (click)="formMode = true; isEditMode = false"
          >
            + Add Question
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-4">
      <ps-questions-list
        (qualificationId)="getQualificationById($event); isEditMode = true"
        [search]="search"
        [filterCategories]="selectedCategories"
        [isReloadQuestions]="isReloadQuestions"
      ></ps-questions-list>
    </div>
    <div class="col-md-8">
      <ps-add-questions
        *ngIf="formMode; else noForm"
        [(formMode)]="formMode"
        [questionData]="questionData"
        [companiesResponse]="companiesResponse"
        [isEditMode]="isEditMode"
        (reloadQuestions)="isReloadQuestions = $event"
      ></ps-add-questions>
      <ng-template #noForm>
        <div class="d_table choose_question">
          <p>
            Choose an existing question or<br />
            click “Add Question” to get started
          </p>
        </div>
      </ng-template>
    </div>
  </div>
</div>
